"use client";
import Script from "next/script";

const GoogleAdsense = () => {
  return (
    <>
      {/* AdSense の自動広告用スクリプトのみを読み込む */}
      <Script
        strategy="afterInteractive"
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2749989130667418"
        crossOrigin="anonymous"
      />
    </>
  );
};

export default GoogleAdsense;
